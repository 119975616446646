import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const IMAGE_BASE_URL = process.env.REACT_APP_CONTENT_IMAGE_BASE_URL;

const BRANDS = gql`
query Brands {
  brands {
    data {
      attributes {
        path
        title
        logo {
          data {
            attributes {
              name url width height
            }
          }
        }
      }
    }
  }
}
`;

function createImageUrl(image) {
  const url = image?.data?.attributes.url;
  return url ? `${IMAGE_BASE_URL}${url}` : ``;
}

function BrandsList() {
  const { loading, error, data } = useQuery(BRANDS);
  return (
    <>
      {loading ? <Spinner /> : null}
      {error ? <BrandsError /> : null}
      {data ?
        <Row className={'brands-list px-5'}>
          {data.brands.data.map((item) => (
            <Col key={item.attributes.path}>
              <Link to={`brands/${item.attributes.path}`} className={'text-decoration-none'}>
                <Card>
                  <Card.Img src={createImageUrl(item.attributes.logo)} />
                  <Card.Body>
                    <Card.Title className={'fw-light'}>
                      {item.attributes.title}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
        : null}
    </>
  );
}

function BrandsError() {
  return (
    <div className={`text-danger`}>
      Sorry, there was a problem. Please try again later.
    </div>
  );
}

export default BrandsList;
