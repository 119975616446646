import React from 'react';
import { Row, Col } from 'react-bootstrap';

function Lost() {
  return (
    <Row>
      <Col>
        <h1>Page Not Found</h1>
      </Col>
    </Row>
  );
}

export default Lost;
