import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from 'react-bootstrap';

function ContentTabulator({ items, activeTitle }) {
  return (
    <Tabs
      className={`mb-3`}
      fill
      defaultActiveKey={activeTitle}
    >
      {items ?
        Object.keys(items).map((title) => (
          <Tab
            key={title}
            eventKey={title}
            title={title}
          >
            {items[title]}
          </Tab>
        ))
        : null}
    </Tabs>
  );
}

export default ContentTabulator;

ContentTabulator.propTypes = {
  activeTitle: PropTypes.string,
  items: PropTypes.objectOf(PropTypes.element),
};
