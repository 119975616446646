import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import ContentFlipCard from './ContentFlipCard';

function ContentFlipCardList({ cardWidth, cardHeight, items }) {
  return (
    <Row
      className={`mb-4`}
    >
      {items?.map((item, i) => (
        <Col
          key={i}
          className={`pb-4`}
        >
          <ContentFlipCard
            width={cardWidth}
            height={cardHeight}
            front={item.front}
            back={item.back}
          />
        </Col>
      ))}
    </Row>
  );
}

export default ContentFlipCardList;

ContentFlipCardList.propTypes = {
  cardWidth: PropTypes.string,
  cardHeight: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    front: PropTypes.shape({ title: PropTypes.string, body: PropTypes.string }),
    back: PropTypes.shape({ title: PropTypes.string, body: PropTypes.string }),
  }))
};
