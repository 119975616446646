import { createAction } from '@reduxjs/toolkit';

export const chooseContentTheme = createAction('choose-content-theme');

export const chooseTopic = createAction('choose-topic');

export const chooseFormat = createAction('choose-format');

export const chooseMessagingTrack = createAction('choose-messaging-track');

export const updateInstruction = createAction('update-instruction');

export const generateText = createAction('generate-text');

export const didGenerateText = createAction('did-generate-text');

export const couldNotGenerateText = createAction('could-not-generate-text');
