import React from 'react';
import CreationForm from './CreationForm';
import { Container } from 'react-bootstrap';

function Create() {
  return (
    <Container fluid className={'create'}>
      <CreationForm />
    </Container>
  );
}

export default Create;
