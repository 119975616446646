import { configureStore, createReducer } from '@reduxjs/toolkit';
import {
  chooseContentTheme,
  chooseTopic,
  chooseFormat,
  chooseMessagingTrack,
  updateInstruction,
  generateText,
  didGenerateText,
  couldNotGenerateText,
} from './actions';

const rootReducer = createReducer(
  {
    contentTheme: '',
    topic: '',
    format: '',
    messagingTrack: '',
    instruction: '',
    generatedTextRequest: null,
    generatedTextResponse: null,
    generatedTextError: null,
  },
  (builder) => {
    builder
      .addCase(chooseContentTheme, (state, action) => {
        state.contentTheme = action.payload;
        state.messagingTrack = '';
      })
      .addCase(chooseTopic, (state, action) => {
        state.topic = action.payload;
        state.messagingTrack = '';
      })
      .addCase(chooseFormat, (state, action) => {
        state.format = action.payload;
      })
      .addCase(chooseMessagingTrack, (state, action) => {
        state.messagingTrack = action.payload;
      })
      .addCase(updateInstruction, (state, action) => {
        state.instruction = action.payload;
      })
      .addCase(generateText, (state, action) => {
        state.generatedTextRequest = action.payload;
        state.generatedTextResponse = null;
        state.generatedTextError = null;
      })
      .addCase(didGenerateText, (state, action) => {
        state.generatedTextResponse = action.payload;
        state.generatedTextError = null;
      })
      .addCase(couldNotGenerateText, (state, action) => {
        state.generatedTextError = action.payload || new Error('unable to generate text');
      })
    ;
  },
);

export const store = configureStore({
  reducer: rootReducer,
});
