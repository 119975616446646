import React, { useMemo } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useBrand } from '../hooks';
import { useDesignStyle } from '../design-styles';

const IMAGE_BASE_URL = process.env.REACT_APP_CONTENT_IMAGE_BASE_URL;

function Header() {
  const { data } = useBrand();
  useDesignStyle(data?.attributes.design);
  const logoSrc = useMemo(() => {
    const url = data?.attributes.nav_logo?.data?.attributes.url;
    return url ? `${IMAGE_BASE_URL}${url}` : ``;
  }, [data]);
  return (
    <>
      <Navbar bg={`dark`} expand={`sm`}>
        <Container fluid>
          <Navbar.Brand as={Link} to={`.`}>
            {logoSrc ?
              <img
                alt={''}
                className={`d-inline-block align-top`}
                height={30}
                src={logoSrc}
              />
              :
              null
            }
            {' '}
            {data?.attributes.title || 'Dashboard'}
          </Navbar.Brand>
          {data &&
            <>
              <Navbar.Toggle aria-controls={`navbar-collapse`}></Navbar.Toggle><Navbar.Collapse id={`navbar-collapse`}>
                <Nav className={`me-auto`}>
                  <Nav.Link as={Link} to={`create`}>Create</Nav.Link>
                </Nav>
                <Nav>
                  <Nav.Link as={Link} to={`/`}>All brands</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </>
          }
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
