import React, { useCallback, useEffect, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  Button,
  Row,
  Col,
  Form,
  Spinner,
  OverlayTrigger,
  Tooltip, Badge,
} from 'react-bootstrap';
import { useQuery, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  chooseContentTheme,
  chooseFormat,
  chooseMessagingTrack,
  chooseTopic,
  couldNotGenerateText,
  didGenerateText,
  generateText,
  updateInstruction,
} from '../store/actions';
import { useComposedPrompt } from '../hooks';
import * as BAM from '../util/bam';
import classNames from 'classnames';
import ContentBlock from '../components/ContentBlock';

const CONTENT_THEMES = gql`
query ContentThemes(
  $brand: String!
) {
  contentThemes(
    pagination:{limit: 100},
    filters: {
      brand: { path: { eq: $brand } }
    }
  ) {
    data {
      id
      attributes {
        title
        body
      }
    }
  }
}
`;

const TOPICS = gql`
query Topics(
  $brand: String!
)
{
  topics(
    pagination:{limit: 100},
    filters: {
      brand: { path: { eq: $brand } }
    }
  ) {
    data {
      id
      attributes {
        title
        body
      }
    }
  }
}
`;

const FORMATS = gql`
query Formats(
  $brand: String!
) {
  formats(
    pagination:{limit: 100},
    filters: { brands: { path: { eq: $brand } } }
  ) {
    data {
      id
      attributes {
        title
        sample
      }
    }
  }
}
`;

const MESSAGING_TRACKS = gql`
query MessagingTracks(
  $contentTheme: ID!,
  $topic: ID!
) {
  messagingTracks(
    filters: {
      content_theme: { id: { eq: $contentTheme } },
      topic: { id: { eq: $topic } }
    }
  ) {
    data {
      id
      attributes {
        title
        body
      }
    }
  }
}
`;

const GET_CREATE = gql`
  query create {
    create {
      data {
        attributes {
          title
          description
        }
      }
    }
  }
`;

function CreationForm() {
  return (
    <>
      <Row className={classNames({
        'px-5': true,
        'pt-2': true,
        'text-center': true,
      })}>

        <RequestView />
        <ResponseView />
      </Row>
      <Row className={classNames({
        'px-5': true,
        'pt-2': true,
        'text-center': true,
      })}>
        <Col>
          <Row xs={1} sm={2} lg={4}>
            <Col className={`mb-4`}>
              <FormatsList />
            </Col>
            <Col className={`mb-4`}>
              <ContentThemesList />
            </Col>
            <Col className={`mb-4`}>
              <TopicsList />
            </Col>
            <Col className={`mb-4`}>
              <MessagingTracksList />
            </Col>
          </Row>
          <Row>
            <Col>
              <InstructionsInput />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className={'text-center'}>
          <SendButton />
        </Col>
      </Row>
    </>
  );
}

function ContentThemesList() {
  const { contentTheme } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { brand } = useParams();
  const { loading, data } = useQuery(CONTENT_THEMES, {
    variables: {
      brand,
    },
  });
  const handleChange = useCallback((id) => {
    dispatch(chooseContentTheme(id));
  }, [dispatch]);
  return (
    <>
      <DropdownSelector
        controlId={`ai-prompt-content-theme`}
        label={`Content theme`}
        value={contentTheme}
        data={data?.contentThemes.data}
        onChange={handleChange}
        loading={loading}
        placeholder={`Choose an option`}
      />
    </>
  )
}

function TopicsList() {
  const { topic } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { brand } = useParams();
  const { loading, data } = useQuery(TOPICS, {
    variables: {
      brand,
    }
  });
  const handleChange = useCallback((id) => {
    dispatch(chooseTopic(id));
  }, [dispatch]);
  return (
    <>
      <DropdownSelector
        controlId={`ai-prompt-topic`}
        label={`Topic`}
        value={topic}
        data={data?.topics.data}
        onChange={handleChange}
        loading={loading}
        placeholder={`Choose an option`}
      />
    </>
  );
}

function FormatsList() {
  const { format } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { brand } = useParams();
  const { loading, data } = useQuery(FORMATS, {
    variables: {
      brand,
    }
  });
  const handleChange = useCallback((id) => {
    dispatch(chooseFormat(id));
  }, [dispatch]);
  return (
    <>
      <DropdownSelector
        controlId={`ai-prompt-document-format`}
        label={`Format`}
        value={format}
        data={data?.formats.data}
        onChange={handleChange}
        loading={loading}
        placeholder={`Choose an option`}
      />
    </>
  );
}

function MessagingTracksList() {
  const { contentTheme, topic, messagingTrack } = useSelector((state) => state);
  const skip = !contentTheme || !topic;
  const dispatch = useDispatch();
  const { loading, data } = useQuery(MESSAGING_TRACKS, {
    variables: { contentTheme, topic },
    skip,
  });
  useEffect(() => {
    if (data?.messagingTracks.data && data.messagingTracks.data.length === 1) {
      const id = data.messagingTracks.data[0].id;
      dispatch(chooseMessagingTrack(id));
    }
  }, [data, dispatch]);
  const handleChange = useCallback((id) => {
    dispatch(chooseMessagingTrack(id));
  }, [dispatch]);
  return (
    <DropdownSelector
      controlId={`ai-prompt-messaging-track`}
      label={`Messaging track`}
      hint={skip ? `First choose content theme and topic.` : ``}
      value={messagingTrack}
      data={data?.messagingTracks.data}
      onChange={handleChange}
      loading={loading}
      placeholder={`Choose an option`}
    />
  );
}

function InstructionsInput() {
  const dispatch = useDispatch();
  const { instruction } = useSelector((state) => state);
  const handleChange = useCallback((event) => {
    dispatch(updateInstruction(event.target.value));
  }, [dispatch]);
  return (
    <Form.Group className={`mb-3`} controlId={`ai-prompt-special-instructions`}>
      <Form.Label>Additional instructions</Form.Label>
      <Form.Control
        as={`textarea`}
        value={instruction}
        onChange={handleChange}
      ></Form.Control>
    </Form.Group>
  );
}

function DropdownSelector({ label, hint, value, data, onChange, loading, controlId, placeholder }) {
  const handleChange = useCallback((event) => {
    const id = event.currentTarget.value;
    onChange(id);
  }, [onChange]);
  const currentItem = useMemo(() => {
    if (data && value) {
      return data.find(item => item.id === value);
    }
  }, [value, data]);
  const count = useMemo(() => {
    if (data) {
      return data.length;
    }
    return '';
  }, [data]);
  return (
    <Form.Group controlId={controlId} className={'dropdown-selector'}>
      <OverlayTrigger
        placement={`top`}
        overlay={hint ? <Tooltip>{hint}</Tooltip> : <></>}
      >
        <Form.Label>
          {label}
          {' '}
          <Badge bg={`info`}>{count}</Badge>
          {loading ? <Spinner size={`sm`} /> : null}
        </Form.Label>
      </OverlayTrigger>
      <OverlayTrigger
        placement={`bottom`}
        overlay={
          currentItem && currentItem.attributes.body ?
            <Tooltip>
              <ReactMarkdown>{currentItem.attributes.body}</ReactMarkdown>
            </Tooltip>
            : <></>
        }
      >
        <Form.Select
          aria-label={label}
          value={value}
          onChange={handleChange}
          disabled={!data}
        >
          {value ? null : <option>{placeholder}</option>}
          {data?.map((item) => (
            <option key={item.id} value={item.id}>{item.attributes.title}</option>
          ))}
        </Form.Select>
      </OverlayTrigger>
    </Form.Group>
  );
}

function FormControlTextArea({ value }) {
  return (
    <>
      <textarea
        className={`form-control`}
        style={{ height: '30vh', minHeight: '15rem' }}
        value={value}
        readOnly
      ></textarea>
    </>
  );
}

function RequestView() {
  const { loading, data } = useComposedPrompt();
  const { data: create_page } = useQuery(GET_CREATE);
  return (
    <>
      {data ?
        <>
          <Row>
            <Col>
              <h1 className={'h5 text-start py-4'}>Your prompt:</h1>
            </Col>
          </Row>
          <FormControlTextArea value={data} />
        </>
        :
        loading ?
          <Spinner />
          :
          <div className={'text-start'}>
            <ContentBlock title={create_page?.create?.data?.attributes?.title || 'Creating Your Own Prompt'}
              label={null}
            >
              <ReactMarkdown>
                {create_page?.create?.data?.attributes?.description || 'To get started, use the drop-down menu to organize your content strategy structure. Choose themes, topics, tracts, and content types. Input your prompt text to add more information, keywords, and actions. This lets you see what\'s generated, and if needed, adjust your language to explore more options.'}
              </ReactMarkdown>
            </ContentBlock>
          </div >
      }
    </>
  );
}

function SendButton() {
  const dispatch = useDispatch();
  const {
    generatedTextRequest,
    generatedTextResponse,
    generatedTextError
  } = useSelector((state) => state);
  const generating = useMemo(() => {
    return generatedTextRequest && !generatedTextResponse && !generatedTextError;
  }, [generatedTextRequest, generatedTextResponse, generatedTextError]);
  const { data } = useComposedPrompt();
  const handleClick = useCallback(() => {
    if (BAM.canGenerateText()) {
      dispatch(generateText(data));
      BAM.generateText(data)
        .then(r => {
          dispatch(didGenerateText(r));
        })
        .catch(e => {
          console.error('Unable to generate text', e);
          dispatch(couldNotGenerateText(e));
        })
        ;
    }
  }, [dispatch, data]);
  return (
    <>
      <Button
        variant={`primary`}
        size={'lg'}
        disabled={!data || generating}
        onClick={handleClick}
      >
        Create
      </Button>
    </>
  );
}

function ResponseView() {
  const {
    generatedTextRequest,
    generatedTextResponse,
    generatedTextError
  } = useSelector((state) => state);
  const text = useMemo(() => {
    if (generatedTextResponse) {
      return generatedTextResponse.results?.map((result) => result.generated_text).join('\n\n\n');
    }
  }, [generatedTextResponse]);
  return (
    <>
      {generatedTextRequest ?
        <>
          <h1 className={'h5 text-start py-4'}>Create&apos;s output for you:</h1>
          {generatedTextError ?
            <div className={`text-danger`}>ERROR: Unable to generate text at this time.</div>
            :
            <>
              {generatedTextResponse ?
                <FormControlTextArea value={text} />
                :
                <Spinner />
              }
            </>
          }
        </>
        : null
      }
    </>
  );
}

export default CreationForm;
