import React from 'react';
import { Container, Row, Col, Navbar } from 'react-bootstrap';
import BrandsList from './BrandsList';
import DashboardBlock from '../components/DashboardBlock';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <>
      <Navbar bg={`dark`} expand={`sm`}>
        <Container fluid>
          <Navbar.Brand as={Link} to={`.`}>Dashboard</Navbar.Brand>
        </Container>
      </Navbar>
      <Container fluid>
        <Row className={'mt-4'}>
          <Col>
            <DashboardBlock />
            <BrandsList />
          </Col>
        </Row>
      </Container>
      <Row className={'footer'}>
        <Col className={'text-bg-dark mt-4 py-5'}>
          {' '}
        </Col>
      </Row>
    </>
  );
}

export default Home;
