import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const BRANDS_BY_PATH = gql`
query BrandByPath($brand: String!) {
  brands(
    filters: {
      path: { eq: $brand }
    }
  ) {
    data {
      attributes {
        title
        design
        logo {
          data {
            attributes {
              name url width height
            }
          }
        }
        nav_logo {
          data {
            attributes {
              name url width height
            }
          }
        }
        learn_layout {
          data {
            attributes {
              label
              content {
                ... on ComponentFeatureBlock {
                  id
                  title
                  superTitle
                  body
                  imagePlacement
                  image {
                    data {
                      attributes {
                        name url width height
                      }
                    }
                  }
                }
                ... on ComponentFeatureTabs {
                  id
                  activeTitle
                  block {
                    title
                    superTitle
                    body
                    imagePlacement
                    image {
                      data {
                        attributes {
                          name url width height
                        }
                      }
                    }
                  }
                  cards {
                    title
                    body
                    image {
                      data {
                        attributes {
                          name url width height
                        }
                      }
                    }
                  }
                }
                ... on ComponentFeatureCardList {
                  id
                  block {
                    title
                    superTitle
                    body
                    imagePlacement
                    image {
                      data {
                        attributes {
                          name url width height
                        }
                      }
                    }
                  }
                  cards {
                    title
                    body
                    image {
                      data {
                        attributes {
                          name url width height
                        }
                      }
                    }
                  }
                }
                ... on ComponentFeatureFlipCardList {
                  id
                  block {
                    title
                    superTitle
                    body
                    imagePlacement
                    image {
                      data {
                        attributes {
                          name url width height
                        }
                      }
                    }
                  }
                  cards {
                    frontTitle
                    frontBody
                    backTitle
                    backBody
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

const PROMPT_COMPOSITION = gql`
query PromptComposition(
  $contentTheme: ID!,
  $topic: ID!,
  $messagingTrack: ID!,
  $format: ID!
) {
  contentTheme(id: $contentTheme) {
    data {
      id
      attributes {
        title
        body
      }
    }
  }
  topic(id: $topic) {
    data {
      id
      attributes {
        title
        body
      }
    }
  }
  messagingTrack(id: $messagingTrack) {
    data {
      id
      attributes {
        title
        body
      }
    }
  }
  format(id: $format) {
    data {
      id
      attributes {
        title
        sample
      }
    }
  }
  proofPoints(
    filters: {
      messaging_tracks: { id: { eq: $messagingTrack } }
    }
  ) {
    data {
      id
      attributes {
        title
        body
      }
    }
  }
}
`;

const composePrompt = (data, instruction) => `\
Content theme: ${data.contentTheme.data.attributes.title}
${data.contentTheme.data.attributes.body}

Topic: ${data.topic.data.attributes.title}
${data.topic.data.attributes.body || ''}

Messaging Track: ${data.messagingTrack.data.attributes.title}
${data.messagingTrack.data.attributes.body}

${data.proofPoints.data.map((proofPoint) => `\
Proof Point: ${proofPoint.attributes.title}
${proofPoint.attributes.body}
`).join('')}

Format sample: ${data.format.data.attributes.title}
${data.format.data.attributes.sample}

${instruction || ''}
`;

export function useBrand() {
  const { brand } = useParams();
  const { loading, error, data } = useQuery(BRANDS_BY_PATH, {
    variables: { brand },
  });
  const brandData = data?.brands.data[0];
  return { loading, error, data: brandData };
}

export function usePromptComposition() {
  const {
    contentTheme,
    topic,
    messagingTrack,
    format,
  } = useSelector((state) => state);
  const enabled = useMemo(() => {
    return contentTheme && topic && messagingTrack && format;
  }, [contentTheme, topic, messagingTrack, format]);
  const { loading, data, error } = useQuery(PROMPT_COMPOSITION, {
    skip: !enabled,
    variables: {
      contentTheme, topic, messagingTrack, format,
    },
  });
  return {
    loading,
    error,
    data,
  };
}

export function useComposedPrompt() {
  const { instruction } = useSelector((state) => state);
  const { loading, error, data } = usePromptComposition();
  const prompt = useMemo(() => {
    return data ? composePrompt(data, instruction) : ``;
  }, [data, instruction]);
  return { loading, error, data: prompt };
}
