import React from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useBrand } from '../hooks';
import Layout from './Layout';

function Learn() {
  const { loading, data } = useBrand();
  return (
    <>
      {loading ? <Spinner /> : null}
      <Container fluid className={'learn'}>
        <Layout content={data?.attributes.learn_layout?.data?.attributes.content} />
      </Container>
    </>
  );
}

export default Learn;
