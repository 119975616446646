import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import ContentCard from './ContentCard';

function ContentCardList({ items, xs, sm, md, lg, xl, xxl }) {
  return (
    <Row
      xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}
      className={`mb-4`}
    >
      {items?.map((item, i) => (
        <Col
          key={i}
          className={`pb-4 d-flex`}
        >
          <ContentCard
            className={`w-100`}
            title={item.title}
            body={item.body}
            imageSrc={item.imageSrc}
          />
        </Col>
      ))}
    </Row>
  );
}

export default ContentCardList;

ContentCardList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(ContentCard.propTypes)),
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  xxl: PropTypes.number,
};
