import React from 'react';
import { Row, Col } from 'react-bootstrap';


function Footer() {
    return (
        <Row className={'footer'}>
            <Col className={'text-bg-dark mt-4 py-5'}>
                {' '}
            </Col>
        </Row>
    );
}


export default Footer;