import React from 'react';
import PropTypes from 'prop-types';
import { Col, Image, Row } from 'react-bootstrap';
import classNames from 'classnames';

function ContentBlock({ children, label, title, imageSrc, imagePlacement }) {
  if (imagePlacement === 'background') {
    return (
      <Row
        className={classNames({
          'content-block': true,
          'content-block--image': imageSrc ? true : false,
        })}
        style={{
          backgroundImage: imageSrc ? `url(${imageSrc})` : ``,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}>
        <Row className={classNames({
            'bg-contrast': imageSrc ? true : false,
            'mx-0': true,
            'p-5': true,
            })}>
          <Col>
            {label ?
              <div className={`h5 subtitle`}>{label}</div>
              : null}
            {title ?
              <h2 className={'title'}>{title}</h2>
              : null}
            {children}
          </Col>
        </Row>
      </Row>
    );
  }
  return (
    <Row
      className={classNames({
        'content-block': true,
        'flex-row-reverse': imagePlacement === 'end',
        'p-5': true,
      })}
    >
      {imageSrc ?
        <Col xs={12} sm={6} md={4}>
          <Image
            className={`mb-3`}
            src={imageSrc}
            fluid
          />
        </Col>
        : null}
      <Col>
        {label ?
          <div className={`h5 subtitle`}>{label}</div>
          : null}
        {title ?
          <h2 className={'title'}>{title}</h2>
          : null}
        {children}
      </Col>
    </Row>
  );
}

export default ContentBlock;

ContentBlock.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  title: PropTypes.string,
  imageSrc: PropTypes.string,
  imagePlacement: PropTypes.oneOf(['start', 'end', 'background']),
};

ContentBlock.defaultProps = {
  imagePlacement: 'start',
};
