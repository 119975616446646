import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';

function ContentCard({ className, title, body, imageSrc, action }) {
  return (
    <Card className={className}>
      {imageSrc ?
        <Card.Img variant={`top`} src={imageSrc} />
        : null}
      <Card.Body className={`d-flex flex-column align-items-start`}>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{body}</Card.Text>
        {action ?
          <Button variant={`link`} className={`mt-auto`}>
            <span className="material-symbols-outlined">arrow_right_alt</span>
          </Button>
          : null}
      </Card.Body>
    </Card>
  );
}

export default ContentCard;

ContentCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  imageSrc: PropTypes.string,
  action: PropTypes.bool,
};
