import React, { Fragment, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import ContentBlock from '../components/ContentBlock';
import ContentTabulator from '../components/ContentTabulator';
import ContentCardList from '../components/ContentCardList';
import ContentFlipCardList from '../components/ContentFlipCardList';

const IMAGE_BASE_URL = process.env.REACT_APP_CONTENT_IMAGE_BASE_URL;

function Layout({ content }) {
  return (
    <>
      {content?.map((item, i) => (
        <Fragment
          key={i}
        >
          <LayoutElement item={item} />
        </Fragment>
      ))}
    </>
  );
}

function createImageUrl(image) {
  // converts image entity from content API into string
  // or empty string if no url can be constructed from the entity
  const url = image?.data?.attributes.url;
  return url ? `${IMAGE_BASE_URL}${url}` : ``;
}

function LayoutElement({ item }) {
  const typename = item.__typename;
  switch (typename) {
    case 'ComponentFeatureBlock':
      return <LayoutBlock item={item} />
    case 'ComponentFeatureTabs':
      return <LayoutTabs item={item} />
    case 'ComponentFeatureCardList':
      return <LayoutCardList item={item} />
    case 'ComponentFeatureFlipCardList':
      return <LayoutFlipCardList item={item} />
    default:
      return <></>;
  }
}

function LayoutBlock({ item, children }) {
  const { superTitle, title, body, image, imagePlacement } = item || {};
  return (
    <ContentBlock
      title={title}
      label={superTitle}
      imagePlacement={imagePlacement}
      imageSrc={createImageUrl(image)}
    >
      <ReactMarkdown>{body}</ReactMarkdown>
      {children}
    </ContentBlock>
  );
}

function LayoutTabs({ item }) {
  const { block, activeTitle, cards } = item;
  // each card has title, body --- and items is a map of title strings to renderables
  const items = useMemo(() => {
    const obj = {};
    if (cards) {
      cards.forEach((card) => {
        obj[card.title] = (
          <ReactMarkdown>{card.body}</ReactMarkdown>
        );
      });
    }
    return obj;
  }, [cards]);
  return (
    <LayoutBlock item={block}>
      <ContentTabulator items={items} activeTitle={activeTitle} />
    </LayoutBlock>
  );
}

function LayoutCardList({ item }) {
  const { block, cards } = item;
  const items = useMemo(() => {
    return cards?.map((card) => {
      return {
        title: card.title,
        body: card.body,
        imageSrc: createImageUrl(card.image),
      }
    });
  }, [cards]);
  const bp = useMemo(() => {
    if (block?.image) {
      return { xs: 1, sm: 1, md: 2, lg: 3, xl: 3 };
    }
    else {
      return { xs: 1, sm: 2, md: 3, lg: 3, xl: 3 };
    }
  }, [block]);
  return (
    <LayoutBlock item={block}>
      <ContentCardList items={items} xs={bp.xs} sm={bp.sm} md={bp.md} lg={bp.lg} xl={bp.xl} />
    </LayoutBlock>
  );
}

function LayoutFlipCardList({ item }) {
  const { block, cards } = item;
  const items = useMemo(() => {
    return cards?.map((card) => {
      return {
        front: {
          title: card.frontTitle,
          body: card.frontBody,
        },
        back: {
          title: card.backTitle,
          body: card.backBody,
        },
      };
    })
  }, [cards]);
  return (
    <LayoutBlock item={block}>
      <ContentFlipCardList items={items} />
    </LayoutBlock>
  );
}

export default Layout;
