import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Placeholder, Card } from 'react-bootstrap';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import ContentBlock from '../components/ContentBlock';

function DashboardBlock() {
  const GET_DASHBOARD = gql`
      query dashboard {
        dashboard {
          data {
            attributes {
              title
              description
            }
          }
        }
      }
    `;

  const { loading, data } = useQuery(GET_DASHBOARD);

  return (
    <>
      {
        loading ?
          <div>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={3} />
            </Placeholder>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={7} />
            </Placeholder>
          </div>
          : null
      }
      <ContentBlock
        title={data?.dashboard?.data?.attributes?.title || 'Your Dashboard'}
        imagePlacement={'background'}
      >
        <ReactMarkdown>{data?.dashboard?.data?.attributes?.description || 'Start your content strategy experience by selecting on of the sub-brands below that you would like to explore or create a prompt for.'}</ReactMarkdown>
      </ContentBlock>
    </>
  )
}

export default DashboardBlock;
