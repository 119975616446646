import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import Header from './views/Header';
import Home from './views/Home';
import Lost from './views/Lost';
import Create from './views/Create';
import Learn from './views/Learn';
import Footer from './components/Footer';

function App() {
  return (
    <Routes>
      <Route exact path={`/`} element={
        <Home />
      } />
      <Route path={`/brands/:brand`}
        element={
          <>
            <Header />
            <Outlet />
            <Footer />
          </>
        }
      >
        <Route path={``} element={<Learn />} />
        <Route path={`create`} element={<Create />} />
      </Route>
      <Route path={`*`} element={<Lost />} />
    </Routes>
  );
}

export default App;
