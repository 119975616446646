import React from 'react';
import './ContentFlipCard.scss';
import ContentCard from './ContentCard';
import PropTypes from 'prop-types';

function ContentFlipCard({ width = '15rem', height = '12rem', front, back }) {
  return (
    <div className="flip-card" style={{
      width, height,
    }}>
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <ContentCard
            className={`h-100`}
            title={front?.title}
            body={front?.body}
            action={true}
          />
        </div>
        <div className="flip-card-back">
          <ContentCard
            className={`h-100`}
            title={back?.title}
            body={back?.body}
          />
        </div>
      </div>
    </div>
  );
}

export default ContentFlipCard;

ContentFlipCard.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  front: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
  }),
  back: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
  }),
};
