import { useEffect } from 'react';

const IMPORTERS = {
    '': () => import('./styles/theme-carbon.scss'),
    'ibm': () => import('./styles/theme-carbon.scss'),
    'shell': () => import('./styles/theme-shell.scss'),
};

function importDesign(design) {
    const importStyle = IMPORTERS[design];
    if (importStyle) {
        importStyle();
    }
    else {
        throw new Error(`Invalid design style: ${design}`);
    }
}

// NOTE: Design originates in the "brand" param as defined in the route.
// param -> brand -> design -> style

let currentDesign;

export function useDesignStyle(design) {
    useEffect(()=>{
        if (design) {
            if (currentDesign) {
                // reload page to clear out current design styles
                window.location.reload();
            }
            else {
                importDesign(design);
                currentDesign = design;
            }
        }
    }, [design]);
}
